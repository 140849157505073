@import url('https://fonts.googleapis.com/css?family=Work+Sans');
@import url('//hello.myfonts.net/count/381000');

@font-face {
  font-family: 'HalisR-Bold';
  src: url('/fonts/381000_0_0.eot');
  src: url('/fonts/381000_0_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/381000_0_0.woff2') format('woff2'),
    url('/fonts/381000_0_0.woff') format('woff'),
    url('/fonts/381000_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisR';
  src: url('/fonts/381000_1_0.eot');
  src: url('/fonts/381000_1_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/381000_1_0.woff2') format('woff2'),
    url('/fonts/381000_1_0.woff') format('woff'),
    url('/fonts/381000_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisR-Medium';
  src: url('/fonts/HalisR-Medium.eot');
  src: url('/fonts/HalisR-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisR-Medium.woff2') format('woff2'),
  url('/fonts/HalisR-Medium.woff') format('woff'),
  url('/fonts/HalisR-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisR-Book';
  src: url('/fonts/HalisR-Book.eot');
  src: url('/fonts/HalisR-Book.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisR-Book.woff2') format('woff2'),
  url('/fonts/HalisR-Book.woff') format('woff'),
  url('/fonts/HalisR-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'VeneerClean';
  src: url('/fonts/381000_2_0.eot');
  src: url('/fonts/381000_2_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/381000_2_0.woff2') format('woff2'),
    url('/fonts/381000_2_0.woff') format('woff'),
    url('/fonts/381000_2_0.ttf') format('truetype');
}

@font-face {
  font-family: BPreplay;
  src: url('/fonts/BPreplay/BPreplay.otf') format('opentype');
}

@font-face {
  font-family: BPreplay;
  font-weight: bold;
  src: url('/fonts/BPreplay/BPreplayBold.otf') format('opentype');
}

@font-face {
  font-family: BPreplay;
  font-style: italic;
  font-weight: bold;
  src: url('/fonts/BPreplay/BPreplayBoldItalics.otf') format('opentype');
}

@font-face {
  font-family: BPreplay;
  font-style: italic;
  src: url('/fonts/BPreplay/BPreplayItalics.otf') format('opentype');
}
