.custom-ql-container {
  width: 100% !important;
  white-space: pre !important;
}
.custom-ql-container .ra-rich-text-input .ql-editor h1,
.custom-ql-container .ra-rich-text-input .ql-editor h2,
.custom-ql-container .ra-rich-text-input .ql-editor h3,
.custom-ql-container .ra-rich-text-input .ql-editor p,
.custom-ql-container .ra-rich-text-input .ql-editor ol,
.custom-ql-container .ra-rich-text-input .ql-editor ul {
  margin: 0;
  padding: 0;
  font-family: HalisR;
}
.custom-ql-container .ra-rich-text-input .ql-editor p:not(:last-child) {
  margin: 0;
}

.custom-ql-container .ra-rich-text-input .ql-editor h1,
.custom-ql-container .ra-rich-text-input .ql-editor h2,
.custom-ql-container .ra-rich-text-input .ql-editor h3 {
  font-family: HalisR-Medium;
  margin-bottom: 12px;
  line-height: 1;
}
.custom-ql-container .ra-rich-text-input .ql-editor h3 {
  font-size: 20px;
}
.custom-ql-container .ra-rich-text-input .ql-editor h2 {
  font-size: 24px;
}
.custom-ql-container .ra-rich-text-input .ql-editor h1 {
  font-size: 32px;
}
.custom-ql-container .ra-rich-text-input .ql-editor em {
  font-style: italic;
}


.custom-ql-container .ra-rich-text-input .ql-editor ol li:not(.ql-direction-rtl)::before {
  top: 0;
  position: relative;
  font-size: 16px;
  margin-right: 0.5rem;
  width: auto;
}
.custom-ql-container .ra-rich-text-input .ql-editor li:not(.ql-direction-rtl)::before {
  font-size: 16px;
  top: 0;
  margin-right: 0.5rem;
  width: auto;
}

.custom-ql-container .ra-rich-text-input .ql-editor li {
  padding-left: 30px;
}
.custom-ql-container .ra-rich-text-input .ql-editor li.ql-indent-1 {
  padding-left: 45px;
}
.custom-ql-container .ra-rich-text-input .ql-editor li.ql-indent-2 {
  padding-left: 60px;
}
.custom-ql-container .ra-rich-text-input .ql-editor li.ql-indent-3 {
  padding-left: 75px;
}
.custom-ql-container .ra-rich-text-input .ql-editor li.ql-indent-4 {
  padding-left: 90px;
}

.custom-ql-container .ra-rich-text-input .ql-editor ol li {
  counter-increment: list-0;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-1 {
  margin-left: 10px;
  counter-increment: list-1;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
  margin-left: 20px;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
  margin-left: 20px;
}

.custom-ql-container .ra-rich-text-input .ql-editor ol li:before {
  content: counter(list-0, decimal) '. ';
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-1:before {
  content: counter(list-0, decimal) '. ' counter(list-1, decimal) '. ';
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-2:before {
  content: counter(list-0, decimal) '. ' counter(list-1, decimal) '. ' counter(list-2, decimal) '. ';
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-3:before {
  content: counter(list-0, decimal) '. ' counter(list-1, decimal) '. ' counter(list-2, decimal) '. '
    counter(list-3, decimal) '. ';
}

.custom-ql-container .ra-rich-text-input .ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.custom-ql-container .ra-rich-text-input .ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
